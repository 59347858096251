import React from "react";
import PropTypes from "prop-types";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import ProductPanel from "../components/organisms/product-panel";
// import CodeBlock from "../components/atoms/code-block";

function DesignBundlePanel({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Bundle Panel" />
      <h2>Bundle Panel</h2>

      <p className="design__text p2">
        The bundle panel is an extension of the product panel. It consists of
        bundle information and multiple products.
      </p>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            title="Bundle Panel"
            component={ProductPanel}
            type="bundle"
            props={designProps}
            wrapperComponent={LatticeGrid}
            wrapperProps={{
              fullWidth: true,
              innerGrid: true,
              className: "bundle-product-hero product-hero",
            }}
          />
          <p className="design__text p2">
            Note that the bundle item component and functionality are not
            currently part of the Quilt design system, but rather built into the
            storefront application. This is mainly due to the reliance of a
            Product component that is also used as a page template in the store
            and not within Quilt.
          </p>
          <p className="design__text p2">
            For this reason, this demo does not include transition events
            between bundle items or a code snippet.
          </p>
        </div>
      </div>
    </div>
  );
}

DesignBundlePanel.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignBundlePanel.defaultProps = {
  props: {},
};

export default DesignBundlePanel;
