// React & Props
import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Internal Components
import OptionGroup from "../molecules/option-group";
// import SwatchGroup from "../molecules/swatch-group";
import Accordion from "../molecules/accordion";
import Button from "../atoms/button";
// import Badge from "../atoms/badge";
import Form from "../molecules/form";
import Input from "../atoms/input";
import Image from "../atoms/image";
// import Textarea from "../atoms/textarea";

// Utils
import {
  getFirstAvailableVariant,
  isGiftCardProduct,
  isAvailable,
  getActiveColor,
  corsFetch,
  mapVariantOptions,
  productHasNoInventory,
} from "../../utils";
import DEFAULT_CONSTANTS from "../../constants";

const SwatchGroup = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-molecules-swatch-group' */ "../molecules/swatch-group"
  ),
);
const Badge = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-badge' */ "../atoms/badge"
  ),
);

function ProductPanel({
  product,
  bundleProduct,
  upsellProduct,
  bundlePosition,
  variants,
  activeVariant,
  setActiveVariant,
  activeQuantity,
  setActiveParams,
  // bundleInfo,
  priceInfo,
  extras,
  setShowOOS,
  setShowDiscount,
  setShowDetails,
  atcAction,
  reviews,
  children,
}) {
  const {
    productCta,
    store: {
      shop: { currentProduct, currentBundle },
    },
  } = useContext(extras.storeContext);
  const { CONSTANTS, params, productInfo } = extras;

  const [preLaunchEmail, setPreLaunchEmail] = useState("");
  const [formMessage, setFormMessage] = useState({
    error: false,
    message: "",
  });
  // const [giftName, setGiftName] = useState("");
  // const [giftNote, setGiftNote] = useState("");
  // const [giftFormMessage, setGiftFormMessage] = useState({
  //   error: false,
  //   message: "",
  // });

  const firstAvailableVariant = getFirstAvailableVariant(product);
  const panelActive =
    activeVariant ||
    firstAvailableVariant ||
    product.shopifyProduct.variants.edges[0].node;
  const panelVariants =
    variants || product.shopifyProduct.variants.edges.map((e) => e.node);

  const usePriceInfo =
    params &&
    priceInfo &&
    priceInfo.newPrice &&
    (priceInfo.discountName || priceInfo.bundleDiscountName) &&
    priceInfo.valid &&
    priceInfo.newPrice !== priceInfo.price &&
    !priceInfo.skipDisplay;

  const panelPriceInfo = priceInfo || {
    price: Math.trunc(panelActive.price.amount * activeQuantity),
  };

  const isGiftCard = isGiftCardProduct(product);

  let atcText = isGiftCard
    ? (CONSTANTS && CONSTANTS.GIFT_CARD_BUTTON_TEXT) ||
      DEFAULT_CONSTANTS.GIFT_CARD_BUTTON_TEXT
    : (CONSTANTS && CONSTANTS.TRY_NOW_BUTTON_TEXT) ||
      DEFAULT_CONSTANTS.TRY_NOW_BUTTON_TEXT;
  let atcColor =
    (CONSTANTS && CONSTANTS.TRY_NOW_BUTTON_COLOR) ||
    DEFAULT_CONSTANTS.TRY_NOW_BUTTON_COLOR;
  let atcActive = true;
  // Use default copy for bundle item
  if (bundleProduct) {
    // Use Review Bundle copy if all other bundle items have a selection
    const inReview =
      currentBundle &&
      currentBundle.items.every(
        (i, index) =>
          bundlePosition === index || (bundlePosition !== index && i.variant),
      );
    atcText = inReview
      ? (CONSTANTS && CONSTANTS.BUNDLE_REVIEW_TEXT) ||
        DEFAULT_CONSTANTS.BUNDLE_REVIEW_TEXT
      : (CONSTANTS && CONSTANTS.BUNDLE_NEXT_TEXT) ||
        DEFAULT_CONSTANTS.BUNDLE_NEXT_TEXT;
  }
  if (
    !currentProduct &&
    (!currentBundle || (currentBundle && currentBundle.upsell))
  ) {
    atcText =
      (CONSTANTS && CONSTANTS.PRELOAD_TEXT) || DEFAULT_CONSTANTS.PRELOAD_TEXT;
    atcActive = false;
  } else if (
    activeVariant &&
    product &&
    !isAvailable(
      activeVariant,
      (CONSTANTS && CONSTANTS.OOS_QUANTITY) || DEFAULT_CONSTANTS.OOS_QUANTITY,
      product,
    )
  ) {
    atcText =
      (CONSTANTS && CONSTANTS.OOS_BUTTON_TEXT) ||
      DEFAULT_CONSTANTS.OOS_BUTTON_TEXT;
    atcColor =
      (CONSTANTS && CONSTANTS.OOS_BUTTON_COLOR) ||
      DEFAULT_CONSTANTS.OOS_BUTTON_COLOR;
    atcActive = false;
  }
  let atcFunc = () => {};
  if (atcActive) {
    atcFunc = (e, giftOptions, bundleOptions) => {
      e.preventDefault();
      if (atcAction) {
        atcAction(e, giftOptions, bundleOptions);
      }
    };
  } else if (
    !currentProduct &&
    (!currentBundle || (currentBundle && currentBundle.upsell))
  ) {
    atcFunc = (e) => {
      e.preventDefault();
      if (setShowDiscount) {
        setShowDiscount(true);
      }
    };
  } else {
    atcFunc = (e) => {
      e.preventDefault();
      if (setShowOOS) {
        setShowOOS(true);
      }
    };
  }

  const extrasDev =
    CONSTANTS && CONSTANTS.DEV && CONSTANTS.DEV.toLowerCase() === "true";
  const isDev =
    extrasDev === null
      ? process.env.DEV && process.env.DEV.toLowerCase() === "true"
      : extrasDev;

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiReturn = corsFetch({
      url: `https://a.klaviyo.com/api/v2/list/${
        CONSTANTS.PRE_LAUNCH_LIST || process.env.PRE_LAUNCH_LIST
      }/subscribe`,
      corsProxy:
        (CONSTANTS && CONSTANTS.CORS_PROXY) || DEFAULT_CONSTANTS.CORS_PROXY,
      dev: isDev,
      data: {
        api_key:
          (CONSTANTS && CONSTANTS.KLAVIYO_API_KEY) ||
          process.env.KLAVIYO_API_KEY,
        profiles: [
          {
            email: preLaunchEmail,
          },
        ],
      },
    });

    apiReturn
      .then(() => {
        setFormMessage({
          error: false,
          message: "Thanks!",
          // mode: "show",
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          message = error.response.data.detail || error.response.data.message;
        } else if (error.request) {
          // console.log(error.request);
        } else {
          message = error.message;
        }
        setFormMessage({
          error: true,
          message: message || "Oops, there was an error. Try again.",
        });
      });
  };

  let formStatus = "";
  if (formMessage.error) {
    formStatus = "error";
  } else if (formMessage.message) {
    formStatus = "success";
  }
  // Determine Pre-Launch View
  let productView = null;
  let productModeClass = "";
  if (bundleProduct && !upsellProduct) {
    productModeClass = "bundle";
  } else if (bundleProduct && upsellProduct) {
    productModeClass = "upsell";
  }
  if (product.preLaunch && !bundleProduct) {
    productView = (
      <>
        <p className="product-panel__pre-launch-text">
          {(CONSTANTS && CONSTANTS.PRE_LAUNCH_COPY) ||
            DEFAULT_CONSTANTS.PRE_LAUNCH_COPY}
        </p>
        <Form
          onSubmit={handleSubmit}
          className="product-panel__pre-launch-form"
          label="Buffy Pre-Lauch Waitlist">
          <Input
            placeholder="Enter Email"
            title="Email"
            type="email"
            name="email"
            id="preLaunchEmail"
            color="buffyOrange"
            onChange={(e) => {
              setPreLaunchEmail(e.target.value);
            }}
            value={preLaunchEmail}
          />
          <Button color="buffyOrange" submit forwardRef={productCta}>
            {(CONSTANTS && CONSTANTS.PRE_LAUCH_BUTTON_TEXT) ||
              DEFAULT_CONSTANTS.PRE_LAUCH_BUTTON_TEXT}
          </Button>
          {formStatus === "error" ? (
            <p className="form__email-error">{formMessage.message}</p>
          ) : null}
          {formStatus === "success" ? (
            <p className="form__email-success">{formMessage.message}</p>
          ) : null}
        </Form>
      </>
    );
  } else {
    productView = product.shopifyProduct.options.map((o) => {
      const variantOptions = mapVariantOptions(product, panelActive);
      let optionIndexActive = o.values.findIndex(
        (v) =>
          (!product.hideOptions ||
            !product.hideOptions.find(
              (ho) =>
                ho.shopifyOption &&
                ho.shopifyOption.name.toLowerCase() === v.toLowerCase(),
            )) &&
          (v === variantOptions[o.name.toLowerCase()] ||
            (variantOptions[o.name.toLowerCase()] &&
              v.toLowerCase() ===
                variantOptions[o.name.toLowerCase()].toLowerCase())),
      );
      optionIndexActive = optionIndexActive < 0 ? 0 : optionIndexActive;
      let optionIndex =
        !upsellProduct && params && params[o.name.toLowerCase()]
          ? o.values.findIndex(
              (v) =>
                (!product.hideOptions ||
                  !product.hideOptions.find(
                    (ho) =>
                      ho.shopifyOption &&
                      ho.shopifyOption.name.toLowerCase() === v.toLowerCase(),
                  )) &&
                (v === params[o.name.toLowerCase()] ||
                  v.toLowerCase() ===
                    params[o.name.toLowerCase()].toLowerCase()),
            )
          : -1;
      optionIndex =
        optionIndex < 0 ||
        (params && !params[o.name.toLowerCase()]) ||
        upsellProduct
          ? optionIndexActive
          : optionIndex;

      if (
        bundleProduct &&
        currentBundle &&
        currentBundle.index !== bundlePosition &&
        !(
          extras &&
          extras.productInfo &&
          extras.productInfo.classNames &&
          extras.productInfo.classNames.includes("addon-image")
        )
      ) {
        if (currentBundle.items[bundlePosition].variant) {
          return (
            <p
              key={`${productModeClass}-item-option-${o.name.toLowerCase()}`}
              className={`${productModeClass}-item-option`}>
              {o.name}: {o.values[optionIndex]}
            </p>
          );
        }
        return null;
      }
      if (o.name.toLowerCase() === "color") {
        return (
          <SwatchGroup
            options={o}
            variants={panelVariants}
            activeVariant={panelActive}
            setActiveVariant={setActiveVariant}
            setActiveParams={setActiveParams}
            activeIndex={optionIndex}
            hideOptions={product.hideOptions}
            colorInfo={extras.colors}
            oosQty={
              (CONSTANTS && CONSTANTS.OOS_QUANTITY) ||
              DEFAULT_CONSTANTS.OOS_QUANTITY
            }
            noInventory={productHasNoInventory(product)}
            key={o.name}
          />
        );
      }
      return (
        <OptionGroup
          options={o}
          variants={panelVariants}
          activeVariant={panelActive}
          setActiveVariant={setActiveVariant}
          setActiveParams={setActiveParams}
          activeIndex={optionIndex}
          hideOptions={product.hideOptions}
          oosQty={
            (CONSTANTS && CONSTANTS.OOS_QUANTITY) ||
            DEFAULT_CONSTANTS.OOS_QUANTITY
          }
          noInventory={productHasNoInventory(product)}
          key={o.name}
        />
      );
    });
    // Add product quantities
    if (product.quantities && !bundleProduct) {
      const quantitiesList = [...product.quantities.sort()].map((ql) =>
        parseInt(ql, 10),
      );
      let quantityIndex = params.quantity
        ? quantitiesList.findIndex((q) => parseInt(q, 10) === params.quantity)
        : 0;
      quantityIndex = quantityIndex < 0 ? 0 : quantityIndex;
      productView = (
        <>
          {productView}
          <OptionGroup
            options={{
              name: "Quantity",
              values: quantitiesList,
            }}
            activeIndex={quantityIndex}
            setActiveParams={setActiveParams}
            summary={
              panelPriceInfo &&
              panelPriceInfo.bundleQuantitySummaryList &&
              panelPriceInfo.bundleQuantitySummaryList.length
                ? panelPriceInfo.bundleQuantitySummaryList.join(" ")
                : null
            }
          />
        </>
      );
    }

    // Add Gift Options to form
    // let giftFormStatus = "";
    // if (giftFormMessage.error) {
    //   giftFormStatus = "error";
    // }
    // isGiftCardProduct(product)
    productView = (
      <>
        {productView}
        {children}
        {(bundleProduct &&
          currentBundle &&
          currentBundle.index !== bundlePosition) ||
        upsellProduct ? null : (
          <>
            <Button
              color={atcColor}
              className={`product-panel__atc ${
                isGiftCard ? "product-panel__atc--sp-btm" : ""
              } ${atcText.length > 18 ? "product-panel__atc--wide" : ""}`}
              inactive={!activeVariant}
              forwardRef={!bundleProduct ? productCta : null}
              onClick={atcFunc}
              label={product && product.heading}>
              {atcText}
            </Button>
            {bundleProduct &&
            currentBundle &&
            currentBundle.index === bundlePosition &&
            product.addon ? (
              <button
                onClick={(e) => atcFunc(e, null, { cancel: true })}
                onKeyPress={(e) => atcFunc(e, null, { cancel: true })}
                type="button"
                className={`${productModeClass}-addon-cancel`}>
                {(CONSTANTS && CONSTANTS.BUNDLE_CANCEL_TEXT) ||
                  DEFAULT_CONSTANTS.BUNDLE_CANCEL_TEXT}
              </button>
            ) : null}
            {isGiftCard ? (
              <span className="product-panel__terms">
                {(CONSTANTS && CONSTANTS.GIFT_TERMS_COPY) ||
                  DEFAULT_CONSTANTS.GIFT_TERMS_COPY}
              </span>
            ) : null}
          </>
        )}
      </>
    );
  }
  const hideTrialDetails =
    productInfo &&
    productInfo.functions &&
    productInfo.functions.PDP &&
    productInfo.functions.PDP.props &&
    productInfo.functions.PDP.props.hide_trial_details;
  const freeTrialDetails = hideTrialDetails
    ? []
    : product.productDetails &&
      product.productDetails.filter(
        (detail) => detail.title === "Free Trial Details",
      );
  const nonFreeTrialDetails =
    product.productDetails &&
    product.productDetails.filter(
      (detail) => detail.title !== "Free Trial Details",
    );

  const detailsText =
    nonFreeTrialDetails &&
    nonFreeTrialDetails.map(
      (detail) =>
        `${detail.shortText ? `<h4>${detail.shortText}</h4>` : ""}${
          detail.longText.childMarkdownRemark.html
        }`,
    );

  let priceInfoEl = <>${panelPriceInfo.price}</>;
  let displayOldPrice = false;
  if (usePriceInfo) {
    priceInfoEl = (
      <>
        <del>${panelPriceInfo.price}</del>
        <ins>${panelPriceInfo.newPrice}</ins>
      </>
    );
    displayOldPrice = true;
  } else if (panelPriceInfo.bundleDiscountName) {
    priceInfoEl = (
      <>
        <del>${panelPriceInfo.price}</del>
        <ins>${panelPriceInfo.bundlePrice}</ins>
      </>
    );
    displayOldPrice = true;
  }

  // Access bundle thumbnail
  let bundleProductBackground = null;
  let bundleProductBackgroundDescription = null;
  if (
    bundleProduct &&
    currentBundle &&
    currentBundle.index !== bundlePosition
  ) {
    const { mobileSliderImages, sliderImages } = product;
    const activeProductColor = getActiveColor({
      activeVariant,
      productColors: extras.colors,
    });

    bundleProductBackground =
      mobileSliderImages && mobileSliderImages[0] && sliderImages[0]
        ? {
            desktop: sliderImages[0],
            mobile: mobileSliderImages[0],
          }
        : {
            desktop: sliderImages[0],
          };
    bundleProductBackgroundDescription = sliderImages[0].description;
    if (extras.colors && extras.colors.length && activeProductColor) {
      if (
        activeProductColor.colorImages &&
        activeProductColor.colorImages.length > 0
      ) {
        bundleProductBackground =
          activeProductColor.mobileColorImages &&
          activeProductColor.mobileColorImages[0]
            ? {
                desktop: activeProductColor.colorImages[0],
                mobile: activeProductColor.mobileColorImages[0],
              }
            : {
                desktop: activeProductColor.colorImages[0],
              };
        bundleProductBackgroundDescription =
          activeProductColor.colorImages[0].description;
      } else {
        bundleProductBackground = activeProductColor.mobileColorImage
          ? {
              desktop: activeProductColor.colorImage,
              mobile: activeProductColor.mobileColorImage,
            }
          : {
              desktop: activeProductColor.colorImage,
            };

        bundleProductBackgroundDescription =
          activeProductColor.colorImage.description;
      }
    }
  }
  const isPreview = Boolean(extras.preview);
  return (
    <div
      className={`product-panel ${
        product.preLaunch ? "product-panel--pre-launch" : ""
      }`}>
      <div className="product-panel__grid">
        <h1
          className={`product-panel__title ${
            displayOldPrice ? "product-panel__title--slim" : ""
          }`}>
          {bundleProduct &&
          currentBundle &&
          currentBundle.index === bundlePosition ? (
            <a href={`/products/${product.shopifyProduct.handle}`}>
              {product.heading || product.title}
            </a>
          ) : (
            product.heading || product.title
          )}
          {/* {product.heading || product.title} */}
        </h1>
        {bundleProduct && !upsellProduct && bundleProductBackground ? (
          <Image
            image={bundleProductBackground}
            alt={bundleProductBackgroundDescription}
            className={`${productModeClass}-item-thumb`}
            loading="eager"
            legacy={isPreview}
          />
        ) : null}
        {product.preLaunch ? (
          <Badge color="yellow" className="product-panel__status">
            New
          </Badge>
        ) : (
          <h2
            className={`product-panel__price ${
              displayOldPrice ? "product-panel__price--wide" : ""
            }`}>
            {priceInfoEl}
          </h2>
        )}

        <p className="product-panel__short">
          {bundleProduct && !upsellProduct
            ? `${!product.addon ? `${bundlePosition + 1}. ` : ""}${
                product.bundleStepMessage ||
                (CONSTANTS && CONSTANTS.BUNDLE_ITEM_DEFAULT_MESSAGE) ||
                DEFAULT_CONSTANTS.BUNDLE_ITEM_DEFAULT_MESSAGE
              }`
            : null}
          {!bundleProduct ? product.shortDescription : null}
        </p>

        {productView}

        {!bundleProduct && nonFreeTrialDetails && (
          <Accordion
            title="Details"
            tag="h3"
            onOpen={() => setTimeout(() => setShowDetails(true), 400)}
            onClose={() => setShowDetails(false)}
            className="product__accordion product__accordion--first">
            <div
              className="accordion__content-list"
              dangerouslySetInnerHTML={{
                __html: detailsText.join(""),
              }}
            />
          </Accordion>
        )}
        {!bundleProduct && freeTrialDetails && freeTrialDetails.length ? (
          <Accordion
            title={freeTrialDetails[0].shortText}
            tag="h3"
            className={`product__accordion ${
              nonFreeTrialDetails ? "" : "product__accordion--first"
            }`}>
            <div
              className="accordion__content-list"
              dangerouslySetInnerHTML={{
                __html: freeTrialDetails[0].longText.childMarkdownRemark.html,
              }}
            />
          </Accordion>
        ) : null}

        {!bundleProduct &&
        reviews &&
        reviews.details &&
        reviews.details.numReviews
          ? useMemo(
              () => (
                <div className="stars">
                  <a
                    className="stars__header"
                    href="#reviewAnchor"
                    title={`Reviews (${reviews.details.score})`}
                    aria-label={`Reviews (${reviews.details.score})`}>
                    <h3 className="stars__title">
                      Reviews ({reviews.details.score})
                    </h3>
                    <Image
                      image={extras.reviewStars}
                      alt="Review stars."
                      legacy={isPreview}
                    />
                  </a>
                </div>
              ),
              [],
            )
          : null}
      </div>
    </div>
  );
}

ProductPanel.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    addon: PropTypes.bool,
    bundleStepMessage: PropTypes.string,
    sliderImages: PropTypes.arrayOf(PropTypes.any),
    mobileSliderImages: PropTypes.arrayOf(PropTypes.any),
    shortDescription: PropTypes.string.isRequired,
    preLaunch: PropTypes.bool,
    quantities: PropTypes.arrayOf(PropTypes.string),
    hideOptions: PropTypes.arrayOf(
      PropTypes.shape({
        shopifyOption: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    shopifyProduct: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
        PropTypes.array,
      ]),
    ).isRequired,
    productDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    upsell: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  bundleProduct: PropTypes.bool,
  upsellProduct: PropTypes.bool,
  bundlePosition: PropTypes.number,
  extras: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
    storeContext: PropTypes.objectOf(PropTypes.any).isRequired,
    reviewStars: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    CONSTANTS: PropTypes.objectOf(PropTypes.any),
    discounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    calcDiscountPrice: PropTypes.func,
    preview: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
    ]),
    productInfo: PropTypes.objectOf(PropTypes.any),
    params: PropTypes.objectOf(PropTypes.any),
  }),
  // bundleInfo: PropTypes.shape({
  //   bundle: PropTypes.objectOf(PropTypes.any),
  //   discount: PropTypes.objectOf(PropTypes.any),
  // }),
  priceInfo: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    newPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    discountName: PropTypes.string,
    valid: PropTypes.bool,
    skipDisplay: PropTypes.bool,
    color: PropTypes.string,
    summary: PropTypes.string,
    bundlePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bundleDiscountName: PropTypes.string,
    bundleSummary: PropTypes.string,
  }),
  activeVariant: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    availableForSale: PropTypes.bool,
    quantityAvailable: PropTypes.number,
  }),
  variants: PropTypes.arrayOf(PropTypes.any),
  setActiveVariant: PropTypes.func,
  activeQuantity: PropTypes.number,
  setActiveParams: PropTypes.shape({
    quantity: PropTypes.func,
    size: PropTypes.func,
    firmness: PropTypes.func,
    color: PropTypes.func,
    value: PropTypes.func,
  }),
  setShowOOS: PropTypes.func,
  setShowDiscount: PropTypes.func,
  setShowDetails: PropTypes.func,
  atcAction: PropTypes.func,
  reviews: PropTypes.shape({
    details: PropTypes.objectOf(PropTypes.any),
    list: PropTypes.arrayOf(PropTypes.any),
  }),
  children: PropTypes.node,
};

ProductPanel.defaultProps = {
  extras: {
    colors: [],
  },
  activeVariant: null,
  variants: null,
  setActiveVariant: null,
  activeQuantity: null,
  setActiveParams: null,
  setShowOOS: null,
  setShowDiscount: null,
  setShowDetails: null,
  atcAction: null,
  reviews: null,
  // bundleInfo: null,
  priceInfo: null,
  bundleProduct: false,
  upsellProduct: false,
  bundlePosition: 0,
  children: null,
};

ProductPanel.displayName = "ProductPanel";

export default ProductPanel;
